import request from '@/service/lib/request';
import { setUrlQuery } from "../utlis/url";
const module = 'statistics';

export function getDeviceOverview () {
    return request({
        url: module + '/getDeviceOverview',
        method: 'get'
    });
}
export function getPersonnelOverview () {
    return request({
        url: module + '/getPersonnelOverview',
        method: 'get'
    });
}

export function getStaffTasks (data) {
    return request({
        url: setUrlQuery({
            url: module + '/getStaffTasks',
            query: data
        }),
        method: 'post',
        data
    });
}


export function getorder (data) {
    return request({
        url: setUrlQuery({
            url: '/company_order?limit=99&page=1&customer_name=&charge=&keyword=&status=1,2,3,4&order_date=',
            query: data
        }),
        method: 'get',
        data
    });
}
