<template>
    <div class="statistmain" :style="{ zoom: wsw / 19.20 + '%' }">
        <div class="stathed">
            <img class="bg" alt="" src="../../img/header.png" />
            <div class="textbox">
                <p>{{ username }}</p>
                <p>MES系统生产管理看板</p>
            </div>
            <div class="tabbox">
                <div class="tab " @click="goto('/statistics')">首页</div>
                <div class="tab" @click="goto('/home')">设备看板</div>
                <div class="tab avtive" @click="goto('/orderOverview')">订单看板</div>
                <div class="tab " @click="goto('/personnelOverview')">人员看板</div>
            </div>
            <div class=" sy" @click="offall">
                进入主控台
            </div>
            <div class="sjbox">
                <p>{{ dayjs(date).format('HH:mm') }}</p>
                <p>{{ dayjs(date).format('YYYY-MM-DD') }}</p>
            </div>
        </div>
        <div class="stebox " v-if="!iscksy">
            <div class="steboxhed">
                订单信息
            </div>
            <div class="setmain">
                <div class="settabbox">
                    <div class="settab " :class="xstype == 'all' ? 'active' : ''" @click="tan('all')">全部
                        <div class="xian"></div>

                    </div>
                    <div class="settab " :class="xstype == item.code ? 'active' : ''" @click="tan(item.code)"
                        v-for="item in ztlist" :key="item.name">{{ item.name }} ({{ item.num }})
                        <div class="xian"></div>
                    </div>
                </div>
                <el-carousel class="ddboxsw" height="calc(100% - 28px)" :interval="3000" indicator-position="outside">
                    <el-carousel-item v-for="(item, index) in swzmd(xslist, 4)" :key="index">
                        <div class="ddbox">
                            <div class="dd" v-for="dd in item" :key="'dd' + dd.id">
                                <div class="typebox">
                                    <span v-if="dd.status == 1" class="type" style="background-color:#136BB8 ;"> 未开工 </span>
                                    <span v-if="dd.status == 2" class="type" style="background-color:#5AE460 ;"> 进行中 </span>
                                    <span v-if="dd.status == 3" class="type" style="background-color:#FA3232 ;"> 逾期 </span>
                                    <span v-if="dd.status == 4" class="type" style="background-color:#FFC635 ;"> 预警 </span>
                                    <span class="name"> {{ dd.name }} [{{ dd.no }}]</span>
                                    <span class="sj">计划完成：{{ dd.plan_end_time }}</span>
                                    <span class="ck" @click="cksy(dd)">查看所有零件({{ dd.items.length }})</span>
                                    <span class="ddjdbox">
                                        <span class="dddj" :style="{ width: dd.progress + '%' }"></span>
                                    </span>
                                    <span class="jdnum">{{ Number(dd.progress).toFixed(0) }}%</span>
                                </div>
                                <div class="gxbox">
                                    <div class="gx" v-for="(lj, index) in dd.items" :key="'lj' + lj.id" v-show="index < 3">
                                        <div class="gxhed">
                                            <!-- <span class="name" id="name">{{ lj.name.length > 8 ? lj.name.substring(0, 8) + '...' : lj.name }}</span> -->
                                            <span  class="name">{{ lj.name }}</span>
                                            <span class="ddjdbox">
                                                <span class="dddj" :style="{ width: lj.progress + '%' }"></span>
                                            </span>
                                            <span class="jdnum">{{ Number(lj.progress).toFixed(0) }}%</span>
                                        </div>
                                        <div class="databox">
                                            <div class="data">零件编号：{{ lj.no }}</div>
                                            <div class="data">图纸型号：{{ lj.drawing_model }}</div>
                                            <div class="data">零件交期：{{ dayjs(lj.plan_end_time).format('YYYY-MM-DD') }}
                                                <span v-if="lj.status == 1" style="color:#ffffff ;"> 未开工
                                                </span>
                                                <span v-if="lj.status == 2" style="color:#5AE460 ;"> 进行中
                                                </span>
                                                <span v-if="lj.status == 3" style="color:#FA3232 ;"> 逾期
                                                </span>
                                                <span v-if="lj.status == 4" style="color:#FFC635 ;"> 预警
                                                </span>
                                            </div>
                                            <div class="data">数量：{{ lj.num }}</div>
                                            <div class="data">材质:{{ lj.material }}</div>
                                        </div>
                                        <div class="gxul">
                                            <!-- <div class="gxli">工序进度</div> -->
                                            <div class="gxli">
                                                <span>工序</span>
                                                <span>完成数量</span>
                                                <span>进度</span>
                                            </div>

                                            <el-carousel height="99px" direction="vertical" :autoplay="true"
                                                indicator-position="none">
                                                <el-carousel-item v-for="(gx, index) in swzmd(lj.process, 3)" :key="index">
                                                    <div class="gxli" v-for="gxdata in gx" :key="gxdata.id">
                                                        <span>{{ gxdata.process_name }}</span>
                                                        <span>{{ gxdata.complete_num ? gxdata.complete_num : 0 }}</span>

                                                        <span>{{ gxdata.progress * 100 }}%</span>

                                                    </div>
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <!-- <div class="gxmain">
                                    <el-carousel height="60px" direction="vertical" :autoplay="true"
                                        indicator-position="none">
                                        <el-carousel-item v-for="(gx, index) in swzmd(lj.process)" :key="index">
                                            <div class="gx" v-for="gxdata in gx" :key="gxdata.id">
                                                <span>{{ gxdata.process_name }}</span>
                                                <span>{{ gxdata.complete_num ? gxdata.complete_num : 0 }}</span>

                                                <span>{{ gxdata.progress * 100 }}%</span>

                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>

                                </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>

            </div>
        </div>
        <div class="stebox" v-else style="height:calc(100% - 40px);overflow: hidden;">
            <div class="steboxhed">
                {{ dd.name }}
            </div>
            <div class="setmain">
                <div class="settabbox">
                    <div class="settab " :class="ddxstype == 'all' ? 'active' : ''" @click="tandd('all')">全部
                        <div class="xian"></div>

                    </div>
                    <div class="settab " :class="ddxstype == item.code ? 'active' : ''" @click="tandd(item.code)"
                        v-for="item in ddlist" :key="item.name">{{ item.name }} ({{ item.num }})
                        <div class="xian"></div>
                    </div>
                </div>
                <div class="ddbox" style="height:calc(100%);overflow: hidden;">
                    <div class="dd" style="width: 98%;height:calc(100% - 60px);">
                        <div class="typebox">
                            <span v-if="dd.status == 1" class="type" style="background-color:#136BB8 ;"> 未开工 </span>
                            <span v-if="dd.status == 2" class="type" style="background-color:#5AE460 ;"> 进行中 </span>
                            <span v-if="dd.status == 3" class="type" style="background-color:#FA3232 ;"> 逾期 </span>
                            <span v-if="dd.status == 4" class="type" style="background-color:#FFC635 ;"> 预警 </span>
                            <span class="name"> {{ dd.name }} [{{ dd.no }}]</span>
                            <span class="sj">计划完成：{{ dd.plan_end_time }}</span>
                            <span class="ck" @click="iscksy = false">返回</span>
                            <span class="ddjdbox">
                                <span class="dddj" :style="{ width: dd.progress + '%' }"></span>
                            </span>
                            <span class="jdnum">{{ Number(dd.progress).toFixed(0) }}%</span>
                        </div>
                        <div class="gxbox" style="padding-left: 20px;overflow-y:scroll;height: 92%;">
                            <div class="gx" v-for="(lj) in dd.items" :key="'lj' + lj.id"
                                style="margin-right: 12px;margin-top: 16px;" v-show="ddxstype == 'all'|| ddxstype == lj.status">
                                <div class="gxhed">
                                    <span class="name">{{ lj.name }}</span>
                                    <span class="ddjdbox">
                                        <span class="dddj" :style="{ width: lj.progress + '%' }"></span>
                                    </span>
                                    <span class="jdnum">{{ Number(lj.progress).toFixed(0) }}%</span>
                                </div>
                                <div class="databox">
                                    <div class="data">零件编号：{{ lj.no }}</div>
                                    <div class="data">图纸型号：{{ lj.drawing_model }}</div>
                                    <div class="data">零件交期：{{ dayjs(lj.plan_end_time).format('YYYY-MM-DD') }}
                                        <span v-if="lj.status == 1" style="color:#ffffff ;"> 未开工
                                        </span>
                                        <span v-if="lj.status == 2" style="color:#5AE460 ;"> 进行中
                                        </span>
                                        <span v-if="lj.status == 3" style="color:#FA3232 ;"> 逾期
                                        </span>
                                        <span v-if="lj.status == 4" style="color:#FFC635 ;"> 预警
                                        </span>
                                    </div>
                                    <div class="data">数量：{{ lj.num }}</div>
                                    <div class="data">材质:{{ lj.material }}</div>
                                </div>
                                <div class="gxul">
                                    <!-- <div class="gxli">工序进度</div> -->
                                    <div class="gxli">
                                        <span>工序</span>
                                        <span>完成数量</span>
                                        <span>进度</span>
                                    </div>

                                    <el-carousel height="99px" direction="vertical" :autoplay="true"
                                        indicator-position="none">
                                        <el-carousel-item v-for="(gx, index) in swzmd(lj.process, 3)" :key="index">
                                            <div class="gxli" v-for="gxdata in gx" :key="gxdata.id">
                                                <span>{{ gxdata.process_name }}</span>
                                                <span>{{ gxdata.complete_num ? gxdata.complete_num : 0 }}</span>

                                                <span>{{ gxdata.progress * 100 }}%</span>

                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                                <!-- <div class="gxmain">
                                    <el-carousel height="60px" direction="vertical" :autoplay="true"
                                        indicator-position="none">
                                        <el-carousel-item v-for="(gx, index) in swzmd(lj.process)" :key="index">
                                            <div class="gx" v-for="gxdata in gx" :key="gxdata.id">
                                                <span>{{ gxdata.process_name }}</span>
                                                <span>{{ gxdata.complete_num ? gxdata.complete_num : 0 }}</span>

                                                <span>{{ gxdata.progress * 100 }}%</span>

                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>

                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
// import { mapGetters } from "vuex";
// import echarts from "echarts";

require("echarts/theme/macarons"); // echarts theme

// import { getMonth } from "@/utlis/date";
// import { bind, orderCountList, staffTaskList } from "@/service/api";
import { getorder } from "@/service/statistics";
import Cookies from 'js-cookie';


export default {
    name: "Home",
    components: {
        // allhed
    },
    data() {
        return {
            wsw: window.screen.width,
            staffdata: null,
            ddData: null,
            sj: new Date(),
            boxSize: 0,  //0最大 5最小
            Screen: false,
            isphone: false,
            count: 0,
            isall: true,
            username: Cookies.get('userNane'),
            logo: Cookies.get('logo'),
            ws: null,
            date: new Date().valueOf(),
            chart: null,
            resizeHandler: null,
            radio1: "今日",
            datevalue1: "",
            swlistdata: [],
            chartList: [
                {
                    list: [
                        { value: 235, name: "开机" },
                        { value: 274, name: "停机" }
                    ]
                },
                {
                    list: [
                        { value: 50, name: "开机" },
                        { value: 80, name: "停机" }
                    ]
                },
                {
                    list: [
                        { value: 90, name: "开机" },
                        { value: 80, name: "停机" }
                    ]
                }
            ],
            list: [],
            onlinenum: "",
            offnum: "",
            ontime: 0,
            //断开链接弹窗
            isws: true,
            xslist: [],
            xstype: 'all',
            ddxstype: 'all',
            ztlist: [
                {
                    name: '未开工',
                    code: '1',
                    num: ''
                },
                {
                    name: '进行中',
                    code: '2',
                    num: ''
                },
                {
                    name: '逾期',
                    code: '3',
                    num: ''
                },
                {
                    name: '预警',
                    code: '4',
                    num: ''
                }
            ],
            ddlist: [
                {
                    name: '未开工',
                    code: '1',
                    num: ''
                },
                {
                    name: '进行中',
                    code: '2',
                    num: ''
                },
                {
                    name: '逾期',
                    code: '3',
                    num: ''
                }

            ],

            iscksy: false,
            dd: null
        };
    },
    computed: {



    },
    watch: {

    },

    methods: {
        tandd(code) {
            this.ddxstype = code;
        },
        cksy(dd) {
            console.log(dd);
            this.iscksy = true;
            this.dd = dd;
            let nun1 = 0;
            let nun2 = 0;
            let nun3 = 0;


            dd.items.forEach(item => {
                if (item.status === 1) {
                    nun1++;
                }
                if (item.status === 2) {
                    nun2++;
                }
                if (item.status === 3) {
                    nun3++;
                }

            });
            this.ddlist[0].num = nun1;
            this.ddlist[1].num = nun2;
            this.ddlist[2].num = nun3;


        },
        tan(type) {
            this.xstype = type;
            this.xsdata();
        },
        xsdata() {
            if (this.xstype === 'all') {
                this.xslist = this.list;
            } else {
                let newlsit = [];
                this.list.forEach(item => {
                    if (item.status === Number(this.xstype)) {
                        newlsit.push(item);
                    }
                });
                this.xslist = newlsit;
            }
        },
        swzmd(arr, num) {
            let newarr = [];
            let newobj = [];

            arr.forEach((item, index) => {
                newobj.push(item);

                if ((index + 1) % num === 0) {
                    newarr.push(newobj);
                    newobj = [];
                }
                if ((index + 1) === arr.length && (index + 1) % num !== 0) {
                    newarr.push(newobj);
                }
            });
            console.log(newarr);
            return newarr;

        },
        goto(url) {
            this.$router.push(url);
        },
        offall() {
            this.$router.push('/sc/companyOrder');
        },

        getPersonnelOverview() {
            getorder().then(res => {
                this.list = res.data.list;
                // let zong = 0;
                let nun1 = 0;
                let nun2 = 0;
                let nun3 = 0;
                let nun4 = 0;
                this.list.forEach(item => {
                    if (item.status === 1) {
                        nun1++;
                    }
                    if (item.status === 2) {
                        nun2++;
                    }
                    if (item.status === 3) {
                        nun3++;
                    }
                    if (item.status === 4) {
                        nun4++;
                    }
                    let zc = 0;
                    let wkg = 0;
                    let yq = 0;
                    item.items.forEach(items => {
                        if (items.status === 1) {
                            wkg++;
                        }
                        if (items.status === 2) {
                            zc++;
                        }
                        if (items.status === 3) {
                            yq++;
                        }
                    });
                    item.zc = zc;
                    item.wkg = wkg;
                    item.yq = yq;

                });
                this.xsdata();
                this.ztlist[0].num = nun1;
                this.ztlist[1].num = nun2;
                this.ztlist[2].num = nun3;
                this.ztlist[3].num = nun4;
                // this.count = zong;
            });
        }
    },
    created() {



    },
    destroyed() {

    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    },
    mounted() {
        const _this = this;
        this.getPersonnelOverview();
        this.timer = setInterval(() => {
            _this.date = new Date(); // 修改数据date
            _this.getPersonnelOverview();
        }, 60000);
        // this.all();

    }
};
</script>
<style>
.el-carousel__indicator.is-active button {
    opacity: 1;
    background-color: #53FFFF !important;
}

body,
html {
    height: 100%;
}

#app {
    height: 100%;
}

body {
    background: #04274D;
}
</style>
<style lang="scss" scoped>
.v-modal {
    z-index: 1 !important;
}

.statistmain {
    // zoom: 80%;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    background: #04274D;
    position: relative;
    padding-bottom: 30px;

    .stathed {
        width: 100%;
        height: 120px;
        position: relative;

        .bg {
            width: 100%;
            height: 200px;
        }

        .textbox {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 0;
            left: 0;
            height: 90px;

            p {
                margin: 0;

                &:nth-child(1) {
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 70px;
                }

                &:nth-child(2) {
                    color: #FFFFFF;
                    line-height: 14px;
                    font-size: 14px;
                }
            }
        }

        .tabbox {
            position: absolute;
            top: 44px;
            left: 40px;

            .tab {
                width: 105px;
                height: 40px;
                background: linear-gradient(180deg, rgba(8, 139, 255, 0.4) 0%, rgba(8, 139, 255, 0) 100%);
                box-shadow: inset 0px 0px 5px 4px rgba(8, 139, 255, 0.6);
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                text-align: center;
                line-height: 40px;
                color: #ACD8FF;
                font-size: 16px;
                float: left;
                margin-right: 32px;
                cursor: pointer;
            }

            .avtive {
                color: #FFFFFF;
                background: linear-gradient(180deg, #088BFF 0%, rgba(8, 139, 255, 0) 100%);
            }
        }

        .sy {
            cursor: pointer;
            width: 120px;
            height: 40px;
            background: linear-gradient(180deg, rgba(8, 139, 255, 0.4) 0%, rgba(8, 139, 255, 0) 100%);
            box-shadow: inset 0px 0px 5px 4px rgba(8, 139, 255, 0.6);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            text-align: center;
            line-height: 40px;
            color: #ACD8FF;
            font-size: 16px;

            margin-right: 32px;
            position: absolute;
            top: 44px;
            right: 140px;
        }

        .sjbox {
            position: absolute;
            top: 44px;
            right: 40px;

            p {
                margin: 0;

                &:nth-child(1) {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                    color: #EFF7FF
                }

                &:nth-child(2) {
                    font-size: 18px;
                    color: #EFF7FF;
                    line-height: 28px;
                }
            }
        }
    }

    .stebox {
        width: calc(100% - 80px);
        margin: 0 auto;
        height: 920px;
        background: rgba(12, 109, 212, 0.2);
        border-radius: 1px 1px 1px 1px;
        opacity: 1;
        border: 1px solid;

        border-image: linear-gradient(225deg, rgba(51, 204, 204, 0), rgba(51, 204, 204, 0.2), rgba(51, 204, 204, 0)) 1 1;


        .steboxhed {
            width: 513px;
            height: 48px;
            background-image: url(../../img/shed.png);
            font-weight: 600;
            font-size: 18px;
            line-height: 48px;
            color: #ACD8FF;
            padding-left: 55px;
            box-sizing: border-box;
        }

        .settabbox {
            width: 100%;
            height: 48px;
            line-height: 48px;
            overflow: hidden;
            padding: 0 40px;
            box-sizing: border-box;

            .settab {
                font-size: 18px;
                font-weight: 400;
                color: #ACD8FF;
                float: left;
                margin-right: 40px;
                position: relative;
                cursor: pointer;

                .xian {
                    content: '';
                    width: 32px;
                    height: 4px;
                    background: #53FFFF;
                    border-radius: 12px 12px 12px 12px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0px;
                    display: none;

                }
            }



            .active {
                color: #53FFFF;

                .xian {
                    display: block;
                }

            }
        }

        .setmain {
            height: calc(100% - 48px);
        }

    }

    .ddboxsw {
        width: 100%;
        height: calc(100% - 48px);
        // height: 100%;
    }

    .ddbox {
        width: 100%;
        overflow-y: scroll;
        height: calc(100% + 18px);
        // height: 100%;
        padding-bottom: 20px;
        box-sizing: border-box;

        .dd {
            width: calc(50% - 24px);
            height: 380px;
            margin-top: 16px;
            background: rgba(21, 154, 255, 0.2);
            margin-left: 16px;
            float: left;

            .typebox {
                height: 40px;
                line-height: 40px;
                background: rgba(21, 154, 255, 0.2);
                width: 100%;
                position: relative;
                box-sizing: border-box;
                padding: 0 12px;

                span {
                    display: inline-block;

                }

                .type {
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    background: #FA3232;
                    border-radius: 4px 4px 4px 4px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFFFF;
                }

                .name {
                    font-size: 16px;
                    font-weight: 500;
                    color: #EFF7FF;
                    margin-left: 16px;
                }

                .sj {
                    font-size: 14px;
                    color: #EFF7FF;
                    margin-left: 16px;
                }

                .ck {
                    // width: 104px;
                    cursor: pointer;
                    padding: 0 8px;
                    height: 25px;
                    background: rgba(21, 154, 255, 0.2);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    text-align: center;
                    line-height: 25px;
                    font-size: 12px;
                    color: #53FFFF;
                    position: absolute;
                    right: 234px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .ddjdbox {
                    width: 160px;
                    height: 12px;
                    background: #1E78CE;
                    border-radius: 49px 49px 49px 49px;
                    opacity: 1;
                    overflow: hidden;
                    position: absolute;
                    right: 60px;
                    top: 50%;
                    transform: translateY(-50%);

                }

                .dddj {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 12px;
                    background: #53FFFF;
                    border-radius: 49px 49px 49px 49px;
                }

                .jdnum {
                    font-weight: 500;
                    font-size: 16px;
                    color: #FFFFFF;
                    position: absolute;
                    right: 16px;
                }
            }
        }

        .gxbox {
            width: 100%;
            box-sizing: border-box;
            padding: 12px;

            .gx {
                width: 280px;
                height: 315px;
                background: rgba(21, 154, 255, 0.1);
                float: left;
                margin-right: 10px;

                &:nth-child(3n) {

                    margin-right: 0;
                }

                .gxhed {
                    width: 100%;
                    line-height: 38px;
                    height: 38px;
                    background: rgba(21, 154, 255, 0.2);
                    position: relative;
                    box-sizing: border-box;
                    padding: 0 12px;

                    .name {
                        display: inline-block;
                        width: 140px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #EFF7FF;
                        white-space:nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .ddjdbox {
                        width: 60px;
                        height: 8px;
                        background: #1E78CE;
                        border-radius: 49px 49px 49px 49px;
                        opacity: 1;
                        overflow: hidden;
                        position: absolute;
                        right: 60px;
                        top: 50%;
                        transform: translateY(-50%);

                    }

                    .dddj {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 8px;
                        background: #53FFFF;
                        border-radius: 49px 49px 49px 49px;
                    }

                    .jdnum {
                        font-weight: 500;
                        font-size: 16px;
                        color: #FFFFFF;
                        position: absolute;
                        right: 16px;
                    }
                }
            }

            .databox {
                width: 100%;
                padding: 0 13px;
                box-sizing: border-box;

                .data {
                    font-size: 14px;
                    color: #EFF7FF;
                    line-height: 26px;
                }
            }
        }

        .gxul {
            width: 100%;
            padding: 0 12px;
            box-sizing: border-box;
            margin-top: 5px;

            .gxli {
                text-align: center;
                font-size: 12px;
                font-weight: 500;
                padding: 0 5px;
                box-sizing: border-box;
                width: 100%;
                color: #EFF7FF;
                background: rgba(21, 154, 255, 0.1);
                height: 32px;
                line-height: 32px;
                border-bottom: 1px solid rgba(21, 154, 255, 0.1);

                span {
                    display: inline-block;
                    text-align: left;
                    width: 25%;

                    &:nth-child(1) {
                        width: 50%;
                    }
                }
            }
        }

    }

}
</style>
